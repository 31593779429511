import * as React from 'react';

import { cn } from '../../utils';

export interface BreakpointsProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export default function Breakpoints({ className, ...props }: BreakpointsProps) {
  const [dimensions, setDimensions] = React.useState<[number, number]>([0, 0]);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setDimensions([window.innerWidth, window.innerHeight]);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (process.env.NODE_ENV !== 'development') return null;
  return (
    <div
      className={cn(
        'rounded-md border border-mosano-black p-2 font-clan-pro text-xs font-normal text-mosano-black shadow-sm',
        className,
      )}
      {...props}
    >
      <p>
        Dimensions: {dimensions[0]}x{dimensions[1]}
      </p>
      <p>
        <span>Breakpoint: </span>
        <span className="inline-block sm:hidden">None</span>
        <span className="hidden sm:inline-block md:hidden">SM</span>
        <span className="hidden md:inline-block lg:hidden">MD</span>
        <span className="hidden lg:inline-block xl:hidden">LG</span>
        <span className="hidden xl:inline-block 2xl:hidden">XL</span>
        <span className="hidden 2xl:inline-block">2XL</span>
      </p>
    </div>
  );
}
