import * as React from 'react';
import { useField } from 'formik';

import { cn } from '../../utils';

export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  name: string;
  label?: React.ReactNode;
}

export default function Checkbox({
  id,
  name,
  label,
  className,
  ...props
}: CheckboxProps) {
  const inputId = id || React.useId();
  const [field] = useField(name);

  return (
    <label
      htmlFor={inputId}
      className={cn('flex w-full flex-row items-start gap-1.5', className)}
    >
      <input
        type="checkbox"
        id={inputId}
        className="mt-1 size-3.5 shrink-0 rounded-sm border border-mosano-blue-500 checked:bg-mosano-blue"
        {...field}
        {...props}
      />
      {label && (
        <span className="font-clan-pro text-sm font-normal leading-6 text-mosano-blue-400">
          {label}
        </span>
      )}
    </label>
  );
}
