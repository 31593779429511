import * as React from 'react';

import emailjs from '@emailjs/browser';
import type { WrapPageElementBrowserArgs } from 'gatsby';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './global.css';

import { Layout } from './src/components/shared';

export const onClientEntry = () => {
  emailjs.init('user_xYVRZdMhAI6rqnPpzv3uE');
};

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  return <Layout>{element}</Layout>;
};
