import * as React from 'react';
import { useField } from 'formik';

import InputLabel from './input-label';
import InputError from './input-error';

import { cn } from '../../utils';

export interface TextareaInputProps
  extends React.ComponentPropsWithoutRef<'textarea'> {
  name: string;
  label?: React.ReactNode;
}

export default function TextareaInput({
  id,
  name,
  label,
  className,
  ...props
}: TextareaInputProps) {
  const inputId = id || React.useId();
  const [field, meta] = useField(name);
  const hasError = meta.touched && !!meta.error;

  return (
    <label htmlFor={inputId} className={cn('block w-full', className)}>
      {label && <InputLabel>{label}</InputLabel>}
      <textarea
        id={inputId}
        rows={3}
        className={cn(
          'block min-h-9.5 w-full resize-y appearance-none bg-mosano-white px-3 py-1.5 font-clan-pro text-base shadow-input placeholder:text-mosano-gray-600',
          hasError && 'shadow-mosano-magenta',
        )}
        {...field}
        {...props}
      />
      {hasError && <InputError>{meta.error}</InputError>}
    </label>
  );
}
