import * as React from 'react';

import { cn } from '../../utils';

export interface InputLabelProps
  extends React.ComponentPropsWithoutRef<'label'> {}

export default function InputLabel({
  className,
  children,
  ...props
}: InputLabelProps) {
  return (
    <span
      className={cn(
        'mb-1 block w-full text-sm leading-8.5 text-mosano-blue lg:text-base',
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
}
