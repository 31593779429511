import * as React from 'react';

import { Link } from 'gatsby';

import { ButtonLink } from './button';

import { cn } from '../../utils';

export interface HeaderProps extends React.ComponentPropsWithoutRef<'header'> {}

export default function Header({ className, ...props }: HeaderProps) {
  return (
    <header
      className={cn(
        'fixed inset-x-0 top-0 z-10 h-16 bg-mosano-white px-6 shadow-hdr xl:h-20',
        className,
      )}
      {...props}
    >
      <div className="relative flex h-full w-full items-center">
        <Link
          to="/"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <img
            width={126}
            height={24}
            alt="Mosano"
            title="Mosano"
            src="/images/mosano.svg"
          />
        </Link>
        <ButtonLink
          to="/#contact"
          className="ml-auto max-sm:w-20.5 max-sm:px-2"
        >
          Let&apos;s Talk
        </ButtonLink>
      </div>
    </header>
  );
}
