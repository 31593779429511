import * as React from 'react';

import * as yup from 'yup';
import { Link } from 'gatsby';

import emailjs from '@emailjs/browser';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';

import * as RDD from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from './button';
import Checkbox from './checkbox';
import TextInput from './text-input';
import TextareaInput from './textarea-input';

import { useIPAddress, useSearchParams } from '../../hooks';
import { usePageProps } from '../../contexts';
import { cn } from '../../utils';

const BLOCKED_DOMAINS = [
  'gmail',
  'outlook',
  'hotmail',
  'live',
  'msn',
  'yahoo',
  'icloud',
  // @NOTE: these domains are commnonly used to bypass the spam filters
  'email',
  'business',
  'buisness',
  'domain',
  'corporate',
];

const isBlockedDomain = (email: string) => {
  const domain = email.split('@')?.[1]?.toLowerCase() || '';
  const rootDomain = domain.split('.')?.[0] || '';
  return BLOCKED_DOMAINS.includes(rootDomain);
};

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
  about: string;
  budget: string;
  privacyPolicy: boolean;
}

const contactFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(256, "The name can't have more than 256 characters")
    .required('The name is required'),
  email: yup
    .string()
    .email('The email must have a valid format')
    .test(
      'blocked-domain',
      'Only business domains are allowed',
      (value) => !value || !isBlockedDomain(value),
    )
    .matches(
      /.+\.[a-zA-Z]+$/,
      'The email must end with a valid domain suffix (e.g., .com, .eu)',
    )
    .max(256, "The email can't have more than 256 characters")
    .required('The email is required'),
  phone: yup
    .string()
    .max(256, "The phone can't have more than 256 characters")
    .optional(),
  about: yup
    .string()
    .min(50, "The message can't have less than 50 characters")
    .max(256, "The message can't have more than 256 characters")
    .required('The message is required'),
  budget: yup.string().required('The budget is required'),
  privacyPolicy: yup.boolean().isTrue().required(),
});

const CONTACT_FORM_DEFAULT_VALUES: ContactFormValues = {
  name: '',
  email: '',
  phone: '',
  about: '',
  budget: '',
  privacyPolicy: false,
};

export interface ContactFormProps
  extends React.ComponentPropsWithoutRef<typeof Form> {}

export default function ContactForm({ className, ...props }: ContactFormProps) {
  const ip = useIPAddress();
  const page = usePageProps();
  const params = useSearchParams(page?.location ?? null);

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const hiddenInputs = React.useMemo(
    () => [
      { name: 'origin', value: 'Your Digital Partner' },
      { name: 'page', value: 'Your Digital Partner' },
      {
        name: 'device',
        value:
          (RDD.isMobile && 'Mobile') ||
          (RDD.isDesktop && 'Desktop') ||
          'Unknown',
      },
      { name: 'user_agent', value: RDD.getUA },
      { name: 'ip', value: ip },
      { name: 'utm_source', value: params.get('utm_source') ?? '' },
      { name: 'utm_medium', value: params.get('utm_medium') ?? '' },
      { name: 'utm_campaign', value: params.get('utm_campaign') ?? '' },
      { name: 'utm_content', value: params.get('utm_content') ?? '' },
      { name: 'utm_term', value: params.get('utm_term') ?? '' },
      { name: 'utm_ad', value: params.get('utm_ad') ?? '' },
      {
        name: 'utm_ad_campaign_id',
        value: params.get('utm_ad_campaign_id') ?? '',
      },
      { name: 'adgroup', value: params.get('adgroup') ?? '' },
      { name: 'cq_cmp', value: params.get('cq_cmp') ?? '' },
      { name: 'gad_source', value: params.get('gad_source') ?? '' },
      { name: 'gclid', value: params.get('gclid') ?? '' },
      { name: 'honeypot', value: '' },
    ],
    [RDD, ip, params],
  );

  const handleSubmit = async (
    values: ContactFormValues,
    helpers: FormikHelpers<ContactFormValues>,
  ) => {
    if (formRef.current) {
      try {
        const formData = new FormData(formRef.current);
        if (formData.get('honeypot') !== '') return; // If this field is filled in, it is probably a bot

        const response = await fetch(
          `https://dns.google/resolve?name=${values.email.split('@')[1]}&type=MX`,
        );
        const data = await response.json();

        if ('Answer' in data && data['Answer'].length > 0) {
          await emailjs.sendForm(
            'mosano-ses',
            'landing-pages',
            formRef.current,
          );

          if (typeof window === 'undefined') return;
          // @ts-ignore Fire a custom event after successful form submission
          window.dataLayer.push({
            event: 'formSubmissionSuccess',
            eventCategory: 'form',
            eventAction: 'submit',
            eventLabel: 'Your Digital Partner Landing Page',
            eventValue: 1,
          });
          window.location.assign('https://mosano.eu/thanks?yourdigitalpartner');
        } else {
          helpers.setFieldError('email', 'The email must have a valid domain');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderPrivacyPolicyLabel = () => {
    return (
      <>
        By submitting this form, I consent to Mosano collecting my information
        for marketing and commercial purposes in accordance with the{' '}
        <Link to="/privacy-policy" className="text-mosano-blue-200 underline">
          Privacy Policy
        </Link>
        . I understand that I can unsubscribe or withdraw my consent at any
        time.
      </>
    );
  };

  return (
    <Formik<ContactFormValues>
      initialValues={CONTACT_FORM_DEFAULT_VALUES}
      validationSchema={contactFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form
          ref={formRef}
          className={cn(
            'max-w-165 overflow-hidden rounded-lg bg-mosano-gray-400 px-7 py-6 shadow-card lg:p-11',
            className,
          )}
          {...props}
        >
          {hiddenInputs.map((input, index) => (
            <input
              key={`hidden-input#${index}`}
              hidden
              readOnly
              type="text"
              id={input.name}
              name={input.name}
              value={input.value}
              className="hidden"
            />
          ))}
          <TextInput
            required
            type="text"
            name="name"
            label="Name"
            inputMode="text"
            autoComplete="name"
            readOnly={isSubmitting}
          />
          <div className="mt-11 flex flex-col items-start gap-6 sm:flex-row">
            <TextInput
              required
              type="email"
              name="email"
              label="Email"
              inputMode="email"
              autoComplete="email"
              readOnly={isSubmitting}
            />
            <TextInput
              type="tel"
              name="phone"
              label="Phone (optional)"
              inputMode="tel"
              autoComplete="tel"
              readOnly={isSubmitting}
            />
          </div>
          <TextareaInput
            required
            name="about"
            label="Message"
            placeholder="Tells us about your project."
            inputMode="text"
            className="mt-11"
            readOnly={isSubmitting}
          />
          <TextInput
            required
            type="text"
            name="budget"
            label="Budget"
            className="mt-11"
            readOnly={isSubmitting}
          />
          <Checkbox
            required
            name="privacyPolicy"
            label={renderPrivacyPolicyLabel()}
            className="mt-5"
          />
          <div className="mt-12 flex flex-col items-center gap-4 sm:flex-row">
            {process.env.GATSBY_RECAPTCHA_SITE_KEY && (
              <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} />
            )}
            <Button type="submit" className="w-full sm:ml-auto sm:w-auto">
              Send Message
            </Button>
          </div>
          <ContactFormErrors />
        </Form>
      )}
    </Formik>
  );
}

interface ContactFormErrorsProps
  extends React.ComponentPropsWithoutRef<'div'> {}

function ContactFormErrors({ className, ...props }: ContactFormErrorsProps) {
  const { errors } = useFormikContext<ContactFormValues>();
  const displayErrors = Object.keys(errors).filter(
    (key) => key !== 'privacyPolicy',
  );

  if (displayErrors.length === 0) return null;
  return (
    <div
      className={cn(
        'mt-5 rounded-md border-2 border-mosano-magenta bg-mosano-magenta/5 p-4 font-clan-pro text-sm text-mosano-magenta',
        className,
      )}
      {...props}
    >
      <ul className="list-disc pl-4">
        {displayErrors.map((key) => (
          <li key={`error#${key}`}>{errors[key as keyof ContactFormValues]}</li>
        ))}
      </ul>
    </div>
  );
}
