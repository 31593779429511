import * as React from 'react';

import Slider, { type Settings as SliderProps } from 'react-slick';

import { cn } from '../../utils';

export interface CarouselProps extends SliderProps {}

export default function Carousel({
  className,
  dotsClass,
  children,
  ...props
}: CarouselProps) {
  return (
    <Slider
      dots
      infinite
      autoplay
      pauseOnHover
      focusOnSelect
      arrows={false}
      slidesToShow={2}
      slidesToScroll={1}
      className={cn(
        'mt-4 [&_.slick-slide>div]:w-full [&_.slick-slide]:flex [&_.slick-slide]:h-auto [&_.slick-slide]:px-1.5 [&_.slick-slide]:py-4 lg:[&_.slick-slide]:p-4 [&_.slick-track]:flex',
        className,
      )}
      dotsClass={cn(
        'slick-dots space-x-1.5 [&>.slick-active>*]:bg-mosano-blue-300 [&>li]:m-0 [&>li]:h-[unset] [&>li]:w-[unset]',
        dotsClass,
      )}
      customPaging={(i) => (
        <div className="size-3 rounded-full border border-mosano-blue-300 bg-mosano-gray-200 transition-colors">
          <span className="sr-only">{i + 1}</span>
        </div>
      )}
      {...props}
    >
      {children}
    </Slider>
  );
}
