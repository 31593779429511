import * as React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '../../utils';

const commentCva = cva('uppercase text-mosano-magenta', {
  variants: {
    size: {
      md: 'text-base leading-5',
      lg: 'text-[1.625rem] leading-8',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type CommentVariantProps = VariantProps<typeof commentCva>;

export interface CommentProps
  extends React.ComponentPropsWithoutRef<'p'>,
    CommentVariantProps {}

export default function Comment({
  size = 'md',
  className,
  children,
  ...props
}: CommentProps) {
  return (
    <p className={cn(commentCva({ size, className }))} {...props}>
      // {children}
    </p>
  );
}
