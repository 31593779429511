import * as React from 'react';

export const ICONS = {
  info: { name: 'Information', icon: '/images/icons/info.svg' },
  location: { name: 'Location', icon: '/images/icons/location.svg' },
  history: { name: 'History', icon: '/images/icons/history.svg' },
  clutch: { name: 'Clutch', icon: '/images/icons/clutch.svg' },
  toolbox: { name: 'Toolbox', icon: '/images/icons/toolbox.svg' },
  'app-store': { name: 'App Store', icon: '/images/icons/app-store.svg' },
  design: { name: 'Design', icon: '/images/icons/design.svg' },
  staff: { name: 'Staff', icon: '/images/icons/staff.svg' },
  people: { name: 'People', icon: '/images/icons/people.svg' },
  'money-bag': { name: 'Money Bag', icon: '/images/icons/money-bag.svg' },
  rocket: { name: 'Rocket', icon: '/images/icons/rocket.svg' },
  apps: { name: 'Apps', icon: '/images/icons/apps.svg' },
  office: { name: 'Office', icon: '/images/icons/office.svg' },
  factory: { name: 'Factory', icon: '/images/icons/factory.svg' },
  star: { name: 'Star', icon: '/images/icons/star.svg' },
  'star-filled': { name: 'Star Filled', icon: '/images/icons/star-filled.svg' },
  'chevron-left': {
    name: 'Chevron Left',
    icon: '/images/icons/chevron-left.svg',
  },
  'chevron-right': {
    name: 'Chevron Right',
    icon: '/images/icons/chevron-right.svg',
  },
  kanban: { name: 'Kanban', icon: '/images/icons/kanban.svg' },
  scrum: { name: 'Agile/Scrum', icon: '/images/icons/scrum.svg' },
  sprint: { name: 'Sprint Reviews', icon: '/images/icons/sprint.svg' },
  'design-thinking': {
    name: 'Design Thinking',
    icon: '/images/icons/design-thinking.svg',
  },
  'product-development': {
    name: 'Product Development',
    icon: '/images/icons/product-development.svg',
  },
  'visual-studio': {
    name: 'Visual Studio',
    icon: '/images/icons/visual-studio.svg',
  },
  figma: { name: 'Figma', icon: '/images/icons/figma.svg' },
  trello: { name: 'Trello', icon: '/images/icons/trello.svg' },
  notion: { name: 'Notion', icon: '/images/icons/notion.svg' },
  'google-sheets': {
    name: 'Google Sheets',
    icon: '/images/icons/google-sheets.svg',
  },
  'google-docs': { name: 'Google Docs', icon: '/images/icons/google-docs.svg' },
  clockify: { name: 'Clockify', icon: '/images/icons/clockify.svg' },
  gitlab: { name: 'GitLab', icon: '/images/icons/gitlab.svg' },
  tailwind: { name: 'TailwindCSS', icon: '/images/icons/tailwind.svg' },
  react: { name: 'ReactJS', icon: '/images/icons/react.svg' },
  'react-native': {
    name: 'React Native',
    icon: '/images/icons/react-native.svg',
  },
  'react-native-paper': {
    name: 'React Native Paper',
    icon: '/images/icons/react-native-paper.svg',
  },
  'react-query': { name: 'React Query', icon: '/images/icons/react-query.svg' },
  'react-wind': { name: 'React Wind', icon: '/images/icons/react-wind.svg' },
  remix: { name: 'Remix', icon: '/images/icons/remix.svg' },
  gatsby: { name: 'Gatsby', icon: '/images/icons/gatsby.svg' },
  mapbox: { name: 'Mapbox', icon: '/images/icons/mapbox.svg' },
  python: { name: 'Python', icon: '/images/icons/python.svg' },
  django: { name: 'Django', icon: '/images/icons/django.svg' },
  celery: { name: 'Celery', icon: '/images/icons/celery.svg' },
  typescript: { name: 'Typescript', icon: '/images/icons/typescript.svg' },
  php: { name: 'PHP', icon: '/images/icons/php.svg' },
  serverless: { name: 'Serverless', icon: '/images/icons/serverless.svg' },
  nodejs: { name: 'NodeJS', icon: '/images/icons/nodejs.svg' },
  java: { name: 'Java', icon: '/images/icons/java.svg' },
  hasura: { name: 'Hasura', icon: '/images/icons/hasura.svg' },
  graphql: { name: 'GraphQL', icon: '/images/icons/graphql.svg' },
  postgresql: { name: 'PostgreSQL', icon: '/images/icons/postgresql.svg' },
  postgis: { name: 'PostGIS', icon: '/images/icons/postgis.svg' },
  mongodb: { name: 'MongoDB', icon: '/images/icons/mongodb.svg' },
  ipfs: { name: 'IPFS', icon: '/images/icons/ipfs.svg' },
  docker: { name: 'Docker', icon: '/images/icons/docker.svg' },
  kubernetes: { name: 'Kubernetes', icon: '/images/icons/kubernetes.svg' },
  terraform: { name: 'Terraform', icon: '/images/icons/terraform.svg' },
  aws: { name: 'AWS', icon: '/images/icons/aws.svg' },
  'aws-eks': { name: 'AWS EKS', icon: '/images/icons/aws-eks.svg' },
  i18n: { name: 'i18n', icon: '/images/icons/i18n.svg' },
  'ci-cd': { name: 'CI/CD', icon: '/images/icons/ci-cd.svg' },
  nlp: { name: 'NLP', icon: '/images/icons/nlp.svg' },
  llm: { name: 'LLM', icon: '/images/icons/llm.svg' },
  'watson-ibm': { name: 'Watson IBM', icon: '/images/icons/watson-ibm.svg' },
  valkey: { name: 'Valkey', icon: '/images/icons/valkey.svg' },
  'horizon-sdk': {
    name: 'Extra Horizon SDK',
    icon: '/images/icons/horizon-sdk.svg',
  },
} as const;

export type IconVariant = keyof typeof ICONS;

export interface IconProps extends React.ComponentPropsWithoutRef<'img'> {
  variant: IconVariant;
}

export default function Icon({ variant, ...props }: IconProps) {
  return (
    <img
      src={ICONS[variant].icon}
      alt={ICONS[variant].name}
      title={ICONS[variant].name}
      {...props}
    />
  );
}
