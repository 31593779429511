import * as React from 'react';

import { Link } from 'gatsby';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../../utils';

const buttonCva = cva(
  [
    'inline-block min-w-0 appearance-none rounded-lg border text-center leading-5 text-mosano-white shadow-btn outline-offset-4 focus:outline-mosano-magenta',
    'min-h-9 px-6 py-1.75 text-sm sm:min-w-32',
    'xl:min-h-12 xl:min-w-51.5 xl:px-6 xl:py-3.25 xl:text-base',
  ],
  {
    variants: {
      variant: {
        default: 'border-transparent bg-mosano-blue',
        outline: 'border-mosano-white bg-transparent',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type ButtonVariantProps = VariantProps<typeof buttonCva>;

export interface ButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    ButtonVariantProps {}

export default function Button({
  variant = 'default',
  className,
  children,
  ...props
}: ButtonProps) {
  return (
    <button
      type="button"
      className={cn(buttonCva({ variant, className }))}
      {...props}
    >
      {children}
    </button>
  );
}

export interface ButtonAnchorProps
  extends React.ComponentPropsWithoutRef<'a'>,
    ButtonVariantProps {}

export function ButtonAnchor({
  variant = 'default',
  className,
  children,
  ...props
}: ButtonAnchorProps) {
  return (
    <a className={cn(buttonCva({ variant, className }))} {...props}>
      {children}
    </a>
  );
}

export interface ButtonLinkProps
  extends React.ComponentPropsWithoutRef<typeof Link>,
    ButtonVariantProps {}

export function ButtonLink({
  variant = 'default',
  className,
  children,
  ...props
}: ButtonLinkProps) {
  return (
    <Link className={cn(buttonCva({ variant, className }))} {...props}>
      {children}
    </Link>
  );
}
