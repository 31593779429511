import * as React from 'react';

import Header from './header';
import Footer from './footer';
import Breakpoints from './breakpoints';

export interface LayoutProps extends React.PropsWithChildren {
  header?: boolean;
  footer?: boolean;
}

export default function Layout({
  header = true,
  footer = true,
  children,
}: LayoutProps) {
  return (
    <React.Fragment>
      <Breakpoints className="fixed left-2 top-2 z-50" />
      {header && <Header />}
      <main className="z-0 grow pt-16 xl:pt-20">{children}</main>
      {footer && <Footer />}
    </React.Fragment>
  );
}
