import * as React from 'react';

import { Link } from 'gatsby';

import Socials from './socials';

import { cn } from '../../utils';

export interface FooterProps extends React.ComponentPropsWithoutRef<'footer'> {}

export default function Footer({ className, ...props }: FooterProps) {
  return (
    <footer
      className={cn('bg-mosano-blue-700 px-16 py-2.5', className)}
      {...props}
    >
      <div className="container mx-auto flex flex-col items-center justify-between gap-2 sm:flex-row">
        <p className="text-sm text-mosano-white">
          &copy; {new Date().getFullYear()} Mosano
        </p>
        <Socials />
        <Link
          to="/privacy-policy"
          className="text-sm text-mosano-white underline"
        >
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
}
