import { extendTailwindMerge } from 'tailwind-merge';
import clsx, { type ClassValue } from 'clsx';

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      text: ['title'],
      shadow: ['btn', 'hdr', 'card', 'input', 'tabs'],
      blur: ['proj-1', 'proj-2'],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
