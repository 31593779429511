import * as React from 'react';

import { cn } from '../../utils';

export interface InputErrorProps
  extends React.ComponentPropsWithoutRef<'span'> {}

export default function InputError({
  className,
  children,
  ...props
}: InputErrorProps) {
  return (
    <span
      className={cn(
        'mt-1 block w-full text-xs leading-5 text-mosano-magenta',
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
}
