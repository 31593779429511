import * as React from 'react';

import { cn } from '../../utils';

const SOCIALS = [
  {
    link: 'https://github.com/mosano-eu',
    name: 'Github',
    icon: '/images/socials/github.svg',
  },
  {
    link: 'https://gitlab.com/mosano',
    name: 'Gitlab',
    icon: '/images/socials/gitlab.svg',
  },
  {
    link: 'https://linkedin.com/company/mosano/',
    name: 'LinkedIn',
    icon: '/images/socials/linkedin.svg',
  },
  {
    link: 'https://instagram.com/mosano.eu/',
    name: 'Instagram',
    icon: '/images/socials/instagram.svg',
  },
  {
    link: 'https://mosano.eu',
    name: 'Mosano',
    icon: '/images/socials/mosano.svg',
  },
] as const;

export interface SocialsProps extends React.ComponentPropsWithoutRef<'div'> {}

export default function Socials({ className, ...props }: SocialsProps) {
  return (
    <div
      className={cn('flex flex-row items-center gap-2.5', className)}
      {...props}
    >
      {SOCIALS.map((social, index) => (
        <a
          key={`social#${index}`}
          href={social.link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block"
        >
          <img
            width={32}
            height={32}
            alt={social.name}
            title={social.name}
            src={social.icon}
            className="size-8"
          />
        </a>
      ))}
    </div>
  );
}
